.tree-table {
  margin-bottom: 2rem;

  .cp_tree-table_viewport {
     height: 200px !important;
  }
}

.width-half {
  width: 50%;
}

.flx {
  display: flex;
  flex-direction: row;
}