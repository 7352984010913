.mainSummaryBox {
  border: 1px solid black;
  padding: 1rem;
  margin-top: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly
}

.summaryBox {
  flex-grow: 1;
  // display: flex;
  // flex-basis: auto;
}