.grid-container-dashboard {
  display: grid;
  grid-template-areas:
    'asset asset alerts alerts alerts'
    'economic assistant assistant assistant assistant';
  gap: 10px;
  padding: 10px;
}

.baseBox {
  border-color: black;
  border-style: solid;
  border-width: 1px;
}

.box-asset {
  @extend .baseBox;
  grid-area: asset;
  height:38vh;
}

.box-alerts {
  @extend .baseBox;
  grid-area: alerts;
  height:38vh;
}

.box-economic {
  @extend .baseBox;
  grid-area: economic;
  height:50vh;
}

.box-assistant {
  @extend .baseBox;
  grid-area: assistant;
  height:50vh;
}
