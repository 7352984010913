ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.typeahead {
  width: 70%;
  display: inline-block;
}

.addButton {
  display: inline-block;
}

.selectedItem {
  padding: 5px;
}

.grid-container {
  display: grid;
  grid-template-areas:
    'multi-chart multi-chart filter'
    'multi-chart multi-chart series'
    'multi-chart multi-chart series';
  gap: 10px;
  padding: 10px;
}

.baseBox {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}

.box-multi-chart {
  grid-area: multi-chart;
  height: 85vh;
}

.box-filter {
  grid-area: filter;
  height: 25vh;
}

.box-series {
  grid-area: series;
  height: calc(60vh - 10px);
}