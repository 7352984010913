.marketIndexDetailsComponents {
  height: 80vh;
}

.container {
  display: flex;
  height: 100%;
}

.tableDiv {
  width: 45%;
  height: 100%;
}

.textArea {
  width: 90%;
}