table, th, td {
  border: 1px solid;
}

th > td {
  font-weight: bold;
}

.baseDetailsGrid {
  display: grid;
  grid-template-areas:
    'details chart chart dateChoose'
    'details table table table';
  gap: 10px;
  padding: 10px;
}

.statTable {
  padding: 0.5rem;
}

.actionButtons {
  padding: 0.5rem;
}

.box {
  border-color: black;
  border-width: 1px;
  border-style: solid;
  padding: 0.5rem;
}

.boxDetails {
  grid-area: details;
}

.boxChart {
  grid-area: chart;
}

.boxDateChoose {
  grid-area: dateChoose;
}

.boxTable {
  grid-area: table;
}