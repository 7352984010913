table {
  width: 100%;
}

th {
  background-color: gray;
  text-align: center;
  font-weight: bold;
}

td {
  text-align: center;
}