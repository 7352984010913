.login-page {
  margin: 2rem auto 0;
  padding: 1rem;
  border: 2px solid;
  display: flex;
  width: 50%;
}

.login-page span,
.login-page input {
  margin: auto;
  width: 80%;
}

.login-page button {
  margin: 1rem auto;
  width: 100%
}