.grid {
  display:flex;
  flex-direction: row;
  // justify-content: center;
  // align-items: center
}

// todo: move to generic class
.alignRight {
  text-align: right;
}

.mHalf {
  margin: 0.5em;
}

label {
  display: inline-block;
  width: 100px;
  text-align: left;
}